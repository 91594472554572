<template>
  <div class="container">
    <div class="columns box-title is-12" style="margin-top: -10px;">
      <div class="column is-6">
        <h4 class="has-text-left" style="margin-top: -26px;">
          <i class="fal fa-list"></i>
          ລາຍລະອຽດສ່ວນແບ່ງ COD
          <span class="countTotal">({{ count }})</span>
        </h4>
      </div>

    </div>
    <div class="box" :style="{marginTop: '-20px'}">
      <div class="columns">
        <div class="column is-2">
          <div class="control">
            <label class="label">ວັນທີສ້າງ:</label>
            <date-picker
                format="MM/YYYY"
                value-type="YYYY-MM"
                type="month"
                v-model="created_date"
                :disabled-date="disabledAfterToday"
                :clearable="false"
                name="created_date"
            ></date-picker>
          </div>
        </div>
        <div v-if="$can('accountingcod')" class="column is-2">
          <div class="control">
            <label class="label">ປະເພດສາຂາ:</label>
            <div class="select">
              <select class="input" v-model="selectedBranchType" @change="fetchBranch">
                <option value="">ທັງໝົດ</option>
                <option value="branch">ສາຂາຫຼັກທັງໝົດ</option>
                <option value="branch_capital">ສາຂາຫຼັກນະຄອນຫຼວງ</option>
                <option value="branch_province">ສາຂາຫຼັກຕ່າງແຂວງ</option>
                <option value="franchise">ສາຂາແຟນຊາຍ</option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="$can('accountingcod')" class="column is-2">
          <div class="control">
            <label class="label">ສາຂາ:</label>
            <model-list-select
                :list="listBranches"
                v-model="selectedBranch"
                placeholder="ເລືອກສາຂາ..."
                option-text="name"
                option-value="id"
                selectedItem="name"
            >
            </model-list-select>
          </div>
        </div>
        <div class="column is-2" style="margin-top: 3px">
          <vs-button
              ref="button" flat
              @click="fetchData()"
              class="form-btn-loading"
              :loading="loading">
            ຄົ້ນຫາ
          </vs-button>
        </div>
        <div class="column is-2" style="margin-top: 3px">
          <vs-button
              ref="button" flat
              @click="exportTotal('all',null)"
              class="form-btn-loading"
              :loading="loading">
            Export
          </vs-button>
        </div>
      </div>
    </div>

    <div v-if="$can('accountingcod')" class="center" :style="{marginTop: '-10px'}">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ລວມຍອດສ້າງບິນ</vs-th>
            <vs-th>ລວມຍອດສ້າງ 1.5%</vs-th>
            <vs-th>ລວມຍອດຈັດສົ່ງ</vs-th>
            <vs-th>ລວມຍອດຈັດສົ່ງ 1.5%</vs-th>
            <vs-th>ລວມມູນຄ່າສ່ວນແບ່ງ ຕົ້ນທາງ</vs-th>
            <vs-th>ລວມມູນຄ່າສ່ວນແບ່ງ ປາຍທາງ</vs-th>
            <vs-th>ລວມຍອດສ່ວນແບ່ງສາຂາ</vs-th>
            <vs-th>ສ່ວນແບ່ງບໍລິສັດ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr :is-selected="true">
            <vs-td>{{ FormatPrice(sumOfCreateBase) }} ກີບ</vs-td>
            <vs-td>{{ FormatPrice(sumOfCreateAmount) }} ກີບ</vs-td>
            <vs-td>{{ FormatPrice(sumOfSuccessBase) }} ກີບ</vs-td>
            <vs-td>{{ FormatPrice(sumOfSuccessAmount) }} ກີບ</vs-td>
            <vs-td>{{ FormatPrice(sumOfCreateResult) }} ກີບ</vs-td>
            <vs-td>{{ FormatPrice(sumOfSuccessResult) }} ກີບ</vs-td>
            <vs-td>{{ FormatPrice(sumOfTotalResult) }} ກີບ</vs-td>
            <vs-td>{{ FormatPrice(sumOfTotalAmount) }} ກີບ</vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th>ສາຂາ</vs-th>
            <vs-th>ຍອດສ້າງບິນ</vs-th>
            <vs-th>ຍອດສ້າງ 1.5%</vs-th>
            <vs-th>ຍອດຈັດສົ່ງ</vs-th>
            <vs-th>ຍອດຈັດສົ່ງ 1.5%</vs-th>
            <vs-th>ມູນຄ່າສ່ວນແບ່ງ ຕົ້ນທາງ</vs-th>
            <vs-th>ມູນຄ່າສ່ວນແບ່ງ ປາຍທາງ</vs-th>
            <vs-th>ລວມຍອດສ່ວນແບ່ງສາຂາ</vs-th>
            <vs-th v-if="$can('accountingcod')">ສ່ວນແບ່ງບໍລິສັດ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr v-for="(item,index) in listBalance" :key="index">
            <vs-td>
              {{ item.name }}
            </vs-td>
            <vs-td>
              {{ FormatPrice(item.create_base) }}
            </vs-td>
            <vs-td>
              {{ FormatPrice(item.create_amount) }}
            </vs-td>
            <vs-td>
              {{ FormatPrice(item.success_base) }}
            </vs-td>
            <vs-td>
              {{ FormatPrice(item.success_amount) }}
            </vs-td>
            <vs-td>
              {{ FormatPrice(item.create_result_amount) }}
            </vs-td>
            <vs-td>
              {{ FormatPrice(item.success_result_amount) }}
            </vs-td>
            <vs-td>
              {{ FormatPrice(item.total_result_amount) }}
            </vs-td>
            <vs-td v-if="$can('accountingcod')">
              {{ FormatPrice(item.total_amount) }}
            </vs-td>
          </vs-tr>
        </template>
        <template #footer>
          <pagination :pagination="pagination" :offset="offset"
                      @paginate="fetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>

    </div>
  </div>
</template>
<script>
import Loading from "@views/Loading.vue";
import {mapActions} from "vuex";
import pagination from '@/Plugins/Paginations/CusorPagination.vue';
import * as helper from "@/Helpers/GetValue";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import {ModelListSelect} from 'vue-search-select';

export default {
  components: {
    Loading,
    pagination,
    DatePicker,
    ModelListSelect
  },

  data: () => ({
    page: 1,
    max: 10,
    created_date: moment(new Date()).format('YYYY-MM'),
    isLoading: false,
    listBalance: [],
    listBranches: [],
    selectedBranch: '',
    selectedBranchType: '',
    count: 0,
    sumOfCreateBase: 0,
    sumOfCreateAmount: 0,
    sumOfSuccessBase: 0,
    sumOfSuccessAmount: 0,
    sumOfCreateResult: 0,
    sumOfSuccessResult: 0,
    sumOfTotalResult: 0,
    sumOfTotalAmount: 0,
    offset: 10,
    limit_page: 10,
    pagination: {},
    loading: false,
  }),

  methods: {
    exportTotal() {
      const loading = this.BtnLoading();
      this.loading = true;
      this.$http.post(`print-cod-branches-balance`, {
        created_date: this.created_date,
        branch_id: this.selectedBranch,
        print: 1
      }, {responseType: 'blob'}).then((res) => {
        const fileUrl = window.URL.createObjectURL(new Blob([res.data]))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        fileLink.setAttribute('download', `ລາຍລະອຽດສ່ວນແບ່ງ COD ` + moment(this.created_date).subtract(1, 'months').format('MM-YYYY') + '.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        this.loading = false;
        loading.close()
      }).catch(() => {
        this.loading = false;
        loading.close()
      });
    },

    FormatPrice(price) {
      let p = (parseInt(price) || 0).toFixed(2).split(".");
      return p[0].split("").reverse().reduce(function (acc, price, i) {
        return price === "-" ? acc : price + (i && !(i % 3) ? "," : "") + acc;
      }, "");
    },

    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },

    fetchData() {
      this.isLoading = true;
      this.fetchInvoiceItemsResponse().then((res) => {
        if (!res.data.error) {
          setTimeout(() => {
            this.listBalance = res.data.data.data.data;
            this.count = res.data.data.total;
            this.sumOfCreateBase = res.data.data.sum_create_base;
            this.sumOfCreateAmount = res.data.data.sum_create_amount;
            this.sumOfSuccessBase = res.data.data.sum_success_base;
            this.sumOfSuccessAmount = res.data.data.sum_success_amount;
            this.sumOfCreateResult = res.data.data.sum_create_result_amount;
            this.sumOfSuccessResult = res.data.data.sum_success_result_amount;
            this.sumOfTotalResult = res.data.data.sum_total_result_amount;
            this.sumOfTotalAmount = res.data.data.sum_total_amount;
            this.pagination = res.data.data.data;
          }, 300);
        }
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },

    fetchInvoiceItemsResponse(options = {}) {
      const {current_page, per_page} = options;
      return this.$http.get('list-cod-branches-balance', {
        params: {
          created_date: this.created_date,
          branch_id: this.selectedBranch,
          branch_type: this.selectedBranchType,
          cursor: current_page ?? this.pagination.cursor,
          per_page: per_page ?? this.limit_page,
        }
      })
    },

    fetchBranch() {
      this.$http.get('v1/listing/' + 'branches' + '?type=' + this.selectedBranchType + '&include=excluded').then((res) => {
        this.listBranches = res.data;
        this.listBranches.unshift({
          id: "",
          name: 'ທັງໝົດ'
        });
      })
    },

    FilterBySearch() {
      helper.GetOldValueOnInput(this);
    },

    ...mapActions({
      LogoutUser: "User/destroyToken",
    }),

    BtnLoading() {
      return this.$vs.loading({
        target: this.$refs.table,
        scale: "1",
        background: "primary",
        opacity: 1,
        color: "#195BFF",
      });
    },
  },
  created() {
    this.fetchBranch();
  },
};
</script>
<style lang="scss" scope>
.container {
  margin-top: 40px;

  .title-login {
    font-size: 20px;
    color: $primary-color;
  }
}

.fit-width {
  white-space: nowrap;
}

.btn-clear {
  background: $crimson;
}

.btn-export-split {
  background: $crimson;

  i {
    width: 25px;

    &:hover {
      color: $white;
    }
  }
}

.btn-export-sum {
  i {
    width: 25px;

    &:hover {
      color: $white;
    }
  }
}

.vs-table__td,
.vs-table__th {
  vertical-align: middle !important;
  white-space: nowrap !important;
  width: 1px !important;
}

</style>
