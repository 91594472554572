import {Validator} from "vee-validate";

const validate = {
    custom: {
        name: {
            required: '(ກະລຸນາປ້ອນກ່ອນ...)',
            min: '(ຊື່ບໍ່ຄວນສັ້ນກວ່າ 2 ໂຕ)',
            max: '(ຊື່ບໍ່ຄວນຍາວກວ່າ 225 ໂຕ)',
        },
        branch_id: {
            required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        },
        size: {
            required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        },
        tel: {
            required: '(ກະລຸນາປ້ອນກ່ອນ...)',
            min: '(ເບີໂທບໍ່ຄວນສັ້ນກວ່າ 4 ໂຕ)',
            max: '(ເບີໂທບໍ່ຄວນຍາວກວ່າ 13 ໂຕ)',
        },
        bank_name: {
            required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        },
        account_number: {
            required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        },
        account_name: {
            required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        },
        create_origin_percentage: {
            required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        },
        create_destination_percentage: {
            required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        },
        sign_origin_percentage: {
            required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        },
        sign_destination_percentage: {
            required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        },

        sign_china_percentage: {
            required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        },
        sign_thailand_percentage: {
            required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        },
        address: {
            required: '(ກະລຸນາປ້ອນກ່ອນ...)',
        },
    }
}
export const ValidateFranchise = (Vue) => {
    Validator.localize('en', validate);
    Vue.$validator.localize('en', validate);
}
