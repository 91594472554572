<template>
  <div class="container">
    <div class="box-title" style="display:flex;justify-content: space-between">
      <h4>
        <i class="fal fa-store-alt"></i>
        ຂໍ້ມູນລາຍການເຈົ້າຂອງເເຟນຊາຍ
        <span class="countTotal"></span>
      </h4>
      <vs-button success style="margin-bottom: 8px;" @click="downLoadFranchiseExcel">
        download excel
      </vs-button>
    </div>
    <div class="box">
      <div class="columns">
        <div class="column is-4">
          <div class="field">
            <div class="control">
              <label class="label">ຄົ້ນຫາ:</label>
              <input
                  class="input"
                  v-model="searchItem"
                  placeholder="ຄົ້ນຫາ..."
                  @keyup.enter="FilterData()"
              >
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="control">
            <label class="label">ເລືອກຂະໜາດເເຟນຊາຍ:</label>
            <div class="field">
              <div class="control">
                <select @change="filterSelectedFranchiseSize" v-model="selectedFranchiseSize" class="input" name="size"
                        v-validate="'required'">
                  <option v-for="(item, index) in franchiseSize" :key="index" :value="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="control" style="display: flex">
            <vs-button color="rgb(59,222,200)"
                       style="margin-top: 24px;border-radius: 4px" @click="downloadExcelTemplate">
              Excel Template
            </vs-button>
            <vs-button @click="importExcelTemplate" color="rgb(52, 73, 94)" gradient
                       style="margin-top: 24px;border-radius: 4px">
              Import Excel
            </vs-button>
            <vs-button style="margin-top: 24px;border-radius: 4px;float: right" @click="onCreateFranchiseOwner">
              ເພີ່ມຂໍ້ມູນ
            </vs-button>
          </div>
        </div>
      </div>
    </div>
    <div class="center">
      <vs-table>
        <template #thead>
          <vs-tr>
            <vs-th></vs-th>
            <vs-th>ສາຂາ</vs-th>
            <vs-th>Size</vs-th>
            <vs-th>ຊື່ເຈົ້າຂອງ</vs-th>
            <vs-th>ເບີໂທ</vs-th>
            <vs-th>ຊື່ທະນາຄານ</vs-th>
            <vs-th>ເລກບັນຊີ</vs-th>
            <vs-th>ຊື່ບັນຊີ</vs-th>
            <vs-th>ເປີເຊັນຍອດສ້າງຕົ້ນທາງ</vs-th>
            <vs-th>ເປີເຊັນຍອດສ້າງປາຍທາງ</vs-th>
            <vs-th>ເປີເຊັນຍອດຈັດສົ່ງຕົ້ນທາງ</vs-th>
            <vs-th>ເປີເຊັນຍອດຈັດສົ່ງປາຍທາງ</vs-th>
            <vs-th>ເປີເຊັນຍອດຈັດສົ່ງຈີນ</vs-th>
            <vs-th>ເປີເຊັນຍອດຈັດສົ່ງໄທ</vs-th>
            <vs-th>ທີ່ຢູ່ປະຈຸບັນເຈົ້າຂອງ</vs-th>
            <vs-th>ທີ່ຕັ້ງສາຂາ</vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr v-for="(item,index) in franchiseList" :key="index">
            <vs-td>
              <div class="group-icon">
                <vs-button circle icon flat @click="updateFranchise(item.id)">
                  <i class="fad fa-pencil-alt"></i>
                </vs-button>
              </div>
            </vs-td>
            <vs-td>
              {{ item.branch_name }}
            </vs-td>
            <vs-td>
              <div class="branch-size">
                {{ item.size }}
              </div>
            </vs-td>
            <vs-td>
              {{ item.owner_name }}
            </vs-td>
            <vs-td>
              {{ item.tel }}
            </vs-td>
            <vs-td>
              {{ item.bank_name }}
            </vs-td>
            <vs-td>
              {{ item.account_number }}
            </vs-td>

            <vs-td>
              {{ item.account_name }}
            </vs-td>
            <vs-td>
              {{ item.create_origin_percentage }}
            </vs-td>
            <vs-td>
              {{ item.create_destination_percentage }}
            </vs-td>
            <vs-td>
              {{ item.sign_origin_percentage }}
            </vs-td>
            <vs-td>
              {{ item.sign_destination_percentage }}
            </vs-td>
            <vs-td>
              {{ item.sign_china_percentage }}
            </vs-td>
            <vs-td>
              {{ item.sign_thailand_percentage }}
            </vs-td>
            <vs-td>
              {{ item.address }}
            </vs-td>
            <vs-td>
              {{ item.branch_address }}
            </vs-td>

          </vs-tr>
        </template>
        <template #footer>
          <pagination :pagination="pagination" :offset="offset"
                      @paginate="FetchData"></pagination>
          <Loading v-if="isLoading"/>
        </template>
      </vs-table>

    </div>

    <ModalAdd>
      <template v-slot="{ close }">
        <CreateFranchise
            @close="close"
            @success="FetchData"
        />
      </template>
    </ModalAdd>
    <ModalEdit>
      <template v-slot="{ close }">
        <UpdateFranchise
            :franchiseOwner="franchiseOwner"
            @close="close"
            @success="FetchData"
        />
      </template>
    </ModalEdit>
    <ModalPreviewFile>
      <template v-slot="{ close }">
        <ImportExcelTemplate
            @close="close"
            @success="FetchData"
        />
      </template>
    </ModalPreviewFile>

  </div>
</template>

<script>
import * as helper from "@/Helpers/GetValue";
import CreateFranchise from "@views/SuperAdmin/FranchiseOwner/CreateFranchise.vue";
import Loading from "@views/Loading.vue";
import pagination from "@/Plugins/Paginations/CusorPagination.vue";
import ImportExcelTemplate from "@views/SuperAdmin/FranchiseOwner/ImportExcelTemplate.vue";
import UpdateFranchise from "@views/SuperAdmin/FranchiseOwner/UpdateFranchise.vue";

export default {
  name: "Index",
  components: {
    pagination,
    CreateFranchise,
    ImportExcelTemplate,
    Loading,
    UpdateFranchise
  },
  data() {
    return {
      selectedFranchiseSize: localStorage.getItem("selectedFranchiseSize") ? localStorage.getItem("selectedFranchiseSize") : '',
      searchItem: "",
      isLoading: false,
      branches: [],
      franchiseSize: [
        {
          id: "",
          name: "ທັງໝົດ"
        },
        {
          id: "S",
          name: "S"
        },
        {
          id: "M",
          name: "M"
        },
        {
          id: "L",
          name: "L"
        },
      ],
      franchiseList: [],
      franchiseOwner: {},
      // Pagination
      count: 0,
      offset: 10,
      per_page: 10,
      pagination: {},
    }
  },
  methods: {
    onCreateFranchiseOwner() {
      this.$store.commit('modalAdd_State', true);
    },

    async updateFranchise(id) {
      try {
        const franchiseOwnerObj = await this.fetchFranchiseOwnerById(id);
        this.franchiseOwner = franchiseOwnerObj.data.data;
        this.$store.commit('modalEdit_State', true);
      } catch (error) {
        console.error("Failed to fetch franchise owner: ", error);
      }
    },
    fetchFranchiseOwnerById(id) {
      return new Promise((resolve, reject) => {
        this.$http.get(`finance/franchise-owner/${id}`).then((res) => {
          resolve(res);
        }).catch((e) => {
          reject(e);
        });
      });
    },
    filterSelectedFranchiseSize() {
      localStorage.setItem("selectedFranchiseSize", this.selectedFranchiseSize)
    },
    FetchData() {
      this.isLoading = true;
      this.fetchItem().then((res) => {
        if (!res.data.error) {
          setTimeout(() => {
            this.franchiseList = res.data.data.data.data;
            this.pagination = res.data.data.data;
          }, 300);
        }
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
    },
    fetchItem(options = {}) {
      const {current_page, per_page} = options;
      const params = {
        search: this.searchItem,
        cursor: current_page ?? this.pagination.cursor,
        per_page: per_page ?? this.per_page,
      };

      if (this.selectedFranchiseSize) {
        params.size = this.selectedFranchiseSize;
      }

      return this.$http.get('finance/list-franchise-owner', {params})
    },
    downloadExcelTemplate() {
      this.$http.post(`finance/print-import-branch-template`, {}, {responseType: 'blob'}).then((res) => {
        const fileUrl = window.URL.createObjectURL(new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        fileLink.setAttribute('download', 'franchise-template' + '.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
      });
    },
    downLoadFranchiseExcel() {
      const params = {
        size: this.selectedFranchiseSize,
        search: this.searchItem,
      }
      this.$http.post(`finance/print-franchise-owner`, params, {responseType: 'blob'}).then((res) => {
        const fileUrl = window.URL.createObjectURL(new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}))
        const fileLink = document.createElement('a');
        fileLink.href = fileUrl;
        fileLink.setAttribute('download', 'franchise' + '.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
      });
    },
    importExcelTemplate() {
      this.$store.commit('modalPreviewFile_State', true);
    },
    FilterData() {
      helper.GetOldValueOnInput(this);
    },

  },
  watch: {
    'selectedFranchiseSize': function () {
      this.FetchData();
    },
    'searchItem': function (value) {
      if (value == '') {
        this.FetchData();
      }
    }
  },
  created() {
    this.FetchData();
  }
}
</script>

<style scoped lang="scss">
.branch-size {
  background: red;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
}

</style>
