<script>
import {Validator} from "vee-validate";

const dict = {
  custom: {
    shipment_file: {
      required: "(ກະລຸນາເລືອກໄຟລ໌...)",
    },
  },
};
Validator.localize("en", dict);
export default {
  props: {
    isCancel: {
      default: false,
    }
  },
  data() {
    return {
      isBtnLoading: false,
      isRecreateForm: false,
      shipment_file: null,
      isValid: true,
      server_errors: {
        shipment_file: null
      }
    }
  },
  methods: {
    validateFile() {
      this.isValid = true;
      const file = this.$refs.fileInput.files[0];
      const allowedMimeTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];

      if (!file) {
        this.isValid = false;
        return;
      }

      if (!allowedMimeTypes.includes(file.type)) {
        this.isValid = false;
      }

      /// set file
      this.shipment_file = null;
      if (this.isValid) {
        this.shipment_file = file
      }
    },
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        this.validateFile();
        if (result && this.isValid) {
          this.SaveItem();
        }
      });
    },
    async SaveItem() {
      this.isBtnLoading = true;
      const formData = new FormData();
      formData.append('shipment_file', this.shipment_file);
      formData.append('is_cancel', this.isCancel ? '1' : '0');

      this.$http.post(`/v1/auth/branches/shipments/imports/orders/excel-settled`, formData).then((res) => {
        this.$emit('close');
        this.$emit('success', res.data);
        this.shipment_file = false
        this.isRecreateForm = true;
        setTimeout(() => {
          this.isRecreateForm = false;
        }, 10)
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
      }).finally(() => {
        this.isBtnLoading = false;
      });
    }
  },
}
</script>

<template>
  <div class="container">
    <div class="form-system-route">
      <div class="box-image">
        <div class="image-route">
          <img :src="require('@/assets/checkBill.png')" alt="">
        </div>
      </div>
      <div class="field column">
        <div style="margin-top: 12px" class="control">
          <label style="margin-right: 10px; margin-top: 2px;" class="label">
            Excel file ລາຍການເລກບິນ {{ isCancel ? 'ທີ່ຈະຍົກເລີກສະຖານະ' : '' }} <span class="text-danger">* {{ errors.first('shipment_file') }}</span>
          </label>
          <input
              v-if="!isRecreateForm"
              class="input"
              type="file"
              name="shipment_file"
              ref="fileInput" accept=".xlsx,.xls"
              v-validate="'required'"
              placeholder="ກະລຸນາເລືອກໄຟລ໌..."
          />
          <label v-if="server_errors.shipment_file" style="margin-right: 10px; margin-top: 2px;" class="label">
            <span class="text-danger"> {{ server_errors.shipment_file }}</span>
          </label>
        </div>
        <div class="con-btns">
          <vs-button
              :color="isCancel ? 'danger' : 'primary'"
              :loading="isBtnLoading"
              @click="ValidateForm"
              ref="button"
              flat
              :class="['form-btn-loading', isCancel ? 'danger' : '']"
          >ບັນທຶກຂໍ້ມູນ
          </vs-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.form-system-route {
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;

  .box-image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .image-route {
      width: 120px;
      height: 120px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

}
</style>
