<script>
import {ModelListSelect} from 'vue-search-select';
import {ValidateFranchise} from "@/Plugins/Validation/ValidateFranchise";

export default {
  components: {
    ModelListSelect,
  },
  data() {
    return {
      franchiseSize: [
        {
          name: "S"
        },
        {
          name: "M"
        },
        {
          name: "L"
        },
      ],
      btnLoading: false,
      branchList: [],
      bankList: [
        {
          name: "BCEL"
        },
        {
          name: "LDB"
        },
      ],

      item: {
        branch_id: "",
        size: "",
        name: "",
        tel: "",
        bank_name: "",
        account_number: "",
        account_name: "",
        create_origin_percentage: "",
        create_destination_percentage: "",
        sign_origin_percentage: "",
        sign_destination_percentage: "",
        sign_china_percentage: "",
        sign_thailand_percentage: "",
        address: ""
      },


      server_errors: {
        branch_id: "",
        size: "",
        name: "",
        tel: "",
        bank_name: "",
        account_number: "",
        account_name: "",
        create_origin_percentage: "",
        create_destination_percentage: "",
        sign_origin_percentage: "",
        sign_destination_percentage: "",
        sign_china_percentage: "",
        sign_thailand_percentage: "",
        address: ""
      }

    }
  },
  methods: {
    FetchListBranches() {
      this.$http.get('finance/list-branches').then((res) => {
        this.branchList = res.data.data;
      })
    },
    ValidateForm() {
      this.$validator.validateAll().then((result) => {
        console.log(result)
        if (result) {
          this.SaveItem();
        }
      });
    },
    SaveItem() {
      this.btnLoading = true;
      this.$http.post('finance/insert-franchise-owner', this.item).then(res => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.btnLoading = false;
            this.$emit('close');
            this.$emit('success');
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
        }
      }).catch(error => {
        this.btnLoading = false;
        if (error.response.status === 422) {
          const obj = error.response.data.errors;       // ໃຊ້ການລູບຂໍ້ມູນເພາະວ່າຂໍ້ມູນ errors ທີ່ສົ່ງມາຈາກ Server ນັ້ນເປັນ Array Object
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
      });
    }
  },
  created() {
    this.FetchListBranches();
    ValidateFranchise(this);
  }
}
</script>

<template>
  <div class="card-form">
    <div class="container">
      <div class="box-title" id="scrollTo">
        <h4>
          <i class="fal fa-plus-circle"></i> ເພີ່ມຂໍ້ມູນ
        </h4>
      </div>

      <div class="columns is-multiline" :style="{marginTop: '20px'}">
        <div class="column is-6">
          <div class="field">
            <div class="control">
              <label class="label">
                ຊື່ເຈົ້າຂອງເເຟນຊາຍ
                <span class="text-danger">* {{ errors.first('name') }}</span>
                <span class="text-danger">{{ server_errors.name }}</span>
              </label>
              <input
                  v-model="item.name"
                  style="width: 100%"
                  class="input" name="name"
                  placeholder=""
                  v-validate="'required'"
              >
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <div class="control">
              <label class="label">
                ຂະໜາດແຟນຊາຍ
                <span class="text-danger">* {{ errors.first('size') }}</span>
                <span class="text-danger">{{ server_errors.size }}</span>
              </label>
              <select v-model="item.size" class="input" name="size" v-validate="'required'">
                <option v-for="(item, index) in franchiseSize" :key="index" :value="item.name">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-12">
          <div class="field">
            <div class="control">
              <label class="label">
                ເລືອກສາຂາ
                <span class="text-danger">* {{ errors.first('branch_id') }}</span>
                <span class="text-danger">{{ server_errors.branch_id }}</span>
              </label>
              <model-list-select
                  v-model="item.branch_id"
                  :list="branchList"
                  option-text="name"
                  option-value="id"
                  selectedItem="name">
              </model-list-select>
            </div>
          </div>
        </div>

        <div class="column is-6">
          <div class="field">
            <div class="control">
              <label class="label">
                ເບີໂທ
                <span class="text-danger">* {{ errors.first('tel') }}</span>
                <span class="text-danger">{{ server_errors.tel }}</span>
              </label>
              <input
                  type="number"
                  v-model="item.tel"
                  style="width: 100%"
                  class="input" name="tel"
                  placeholder=""
                  v-validate="'required'"
              >
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <div class="control">
              <label class="label">
                ຊື່ທະນາຄານ
                <span class="text-danger">* {{ errors.first('bank_name') }}</span>
                <span class="text-danger">{{ server_errors.bank_name }}</span>
              </label>
              <select v-model="item.bank_name" class="input" name="size" v-validate="'required'">
                <option v-for="(item, index) in bankList" :key="index" :value="item.name">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <div class="control">
              <label class="label">
                ຊື່ບັນຊີທະນາຄານ
                <span class="text-danger">* {{ errors.first('account_name') }}</span>
                <span class="text-danger">{{ server_errors.account_name }}</span>
              </label>
              <input
                  v-model="item.account_name"
                  style="width: 100%"
                  class="input" name="account_name"
                  placeholder=""
                  v-validate="'required'"
              >
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <div class="control">
              <label class="label">
                ເລກບັນຊີ
                <span class="text-danger">* {{ errors.first('account_number') }}</span>
                <span class="text-danger">{{ server_errors.account_number }}</span>
              </label>
              <input
                  type="number"
                  v-model="item.account_number"
                  style="width: 100%"
                  class="input" name="account_number"
                  placeholder=""
                  v-validate="'required'"
              >
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <div class="control">
              <label class="label">
                ເປີເຊັນຍອດສ້າງຕົ້ນທາງ
                <span class="text-danger">* {{ errors.first('create_origin_percentage') }}</span>
                <span class="text-danger">{{ server_errors.create_origin_percentage }}</span>
              </label>
              <input
                  type="number"
                  v-model="item.create_origin_percentage"
                  style="width: 100%"
                  class="input" name="create_origin_percentage"
                  placeholder=""
                  v-validate="'required'"
              >
            </div>
          </div>
        </div>

        <div class="column is-6">
          <div class="field">
            <div class="control">
              <label class="label">
                ເປີເຊັນຍອດສ້າງປາຍທາງ
                <span class="text-danger">* {{ errors.first('create_destination_percentage') }}</span>
                <span class="text-danger">{{ server_errors.create_destination_percentage }}</span>
              </label>
              <input
                  type="number"
                  v-model="item.create_destination_percentage"
                  style="width: 100%"
                  class="input" name="create_destination_percentage"
                  placeholder=""
                  v-validate="'required'"
              >
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <div class="control">
              <label class="label">
                ເປີເຊັນຍອດຈັດສົ່ງຕົ້ນທາງ
                <span class="text-danger">* {{ errors.first('sign_origin_percentage') }}</span>
                <span class="text-danger">{{ server_errors.sign_origin_percentage }}</span>
              </label>
              <input
                  type="number"
                  v-model="item.sign_origin_percentage"
                  style="width: 100%"
                  class="input" name="sign_origin_percentage"
                  placeholder=""
                  v-validate="'required'"
              >
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <div class="control">
              <label class="label">
                ເປີເຊັນຍອດຈັດສົ່ງປາຍທາງ
                <span class="text-danger">* {{ errors.first('sign_destination_percentage') }}</span>
                <span class="text-danger">{{ server_errors.sign_destination_percentage }}</span>
              </label>
              <input
                  type="number"
                  v-model="item.sign_destination_percentage"
                  style="width: 100%"
                  class="input" name="sign_destination_percentage"
                  placeholder=""
                  v-validate="'required'"
              >
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <div class="control">
              <label class="label">
                ເປີເຊັນຍອດຈັດສົ່ງຈີນ
                <span class="text-danger">* {{ errors.first('sign_china_percentage') }}</span>
                <span class="text-danger">{{ server_errors.sign_china_percentage }}</span>
              </label>
              <input
                  type="number"
                  v-model="item.sign_china_percentage"
                  style="width: 100%"
                  class="input" name="sign_china_percentage"
                  placeholder=""
                  v-validate="'required'"
              >
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <div class="control">
              <label class="label">
                ເປີເຊັນຍອດຈັດສົ່ງໄທ
                <span class="text-danger">* {{ errors.first('sign_thailand_percentage') }}</span>
                <span class="text-danger">{{ server_errors.sign_thailand_percentage }}</span>
              </label>
              <input
                  type="number"
                  v-model="item.sign_thailand_percentage"
                  style="width: 100%"
                  class="input" name="sign_thailand_percentage"
                  placeholder=""
                  v-validate="'required'"
              >
            </div>
          </div>
        </div>

        <div class="column is-12">
          <div class="field">
            <div class="control">
              <label class="label">
                ທີ່ຢູ່
                <span class="text-danger">* {{ errors.first('address') }}</span>
                <span class="text-danger">{{ server_errors.address }}</span>
              </label>
              <input
                  v-model="item.address"
                  style="width: 100%"
                  class="input" name="address"
                  placeholder=""
                  v-validate="'required'"
              >
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-12" id="box-btn-save">
          <div ref="target" id="target" class="center">
            <div class="con-btns">
              <vs-button ref="button" flat class="form-btn-loading" @click="ValidateForm" :loading="btnLoading">
                <i class="fal fa-box-full"></i> ບັນທຶກ
              </vs-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.card-form {
  width: 600px;
}
</style>
